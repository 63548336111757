import { Link, useLocation, useNavigate } from 'react-router-dom';
import { TiSocialLinkedin } from 'react-icons/ti';
import { FaFacebookF } from 'react-icons/fa6';
import { FaInstagram } from 'react-icons/fa';
import { images } from '../../constants/images';
import { OrganizationDetails } from '../../hooks/useFetchOrganizationDetails';
import { useEffect, useRef } from 'react';

interface Props {
  data: OrganizationDetails | null;
}

const Footer = ({ data }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const removeBackground = async () => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = data?.logo || ''; // Replace with your image source

      img.onload = () => {
        if (!canvasRef.current) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        if (!ctx) return;

        // Set canvas dimensions to match the image
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Get image data for manipulation
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        // Loop through each pixel to remove the white background
        for (let i = 0; i < data.length; i += 4) {
          const r = data[i];
          const g = data[i + 1];
          const b = data[i + 2];

          // Set alpha to 0 for white-like pixels
          if (r > 200 && g > 200 && b > 200) {
            data[i + 3] = 0;
          }
        }

        // Put the modified image data back onto the canvas
        ctx.putImageData(imageData, 0, 0);
      };

      img.onerror = error => {
        console.error('Failed to load the image', error);
      };
    };

    if (data?.logo) {
      removeBackground();
    }
  }, [data]);

  return (
    <>
      <footer className='w-full bg-white text-black px-6 md:px-16 py-6 md:py-10 border-t border-[#D7D7D7]'>
        {/* <img src={data?.logo} alt="" className="w-[11.68rem]" /> */}
        {data?.logo && (
          <canvas ref={canvasRef} className='max-w-52 md:max-w-64 max-h-20 ' />
        )}
        <div className='grid grid-cols-2 md:grid-cols-3 text-center md:text-left mt-6'>
          <div className='flex flex-col justify-center items-start md:justify-start md:mx-0'>
            <Link
              className='py-2 font-semibold'
              to={'/business-faq'}
              onClick={() => {
                if (pathname === '/business-faq') {
                  window.scrollTo(0, 0);
                }
              }}
            >
              FAQ
            </Link>

            <Link
              className='py-2 font-semibold'
              to={'/terms-and-conditions'}
              onClick={() => {
                // if (pathname === '/terms-and-conditions') {
                window.scrollTo(0, 0);
                // }
              }}
            >
              Terms of use
            </Link>
            <Link
              className='py-2 font-semibold'
              to={'/privacy-policy'}
              onClick={() => {
                // if (pathname === '/privacy-policy') {
                window.scrollTo(0, 0);
                // }
              }}
            >
              Privacy Policy
            </Link>
            {/* <Link
              className='py-2 font-semibold hover:underline'
              to={'/privacy-policy'}
              onClick={() => {
                if (pathname === '/about-us') {
                  window.scrollTo(0, 0);
                }
              }}
            >
              How it works
            </Link> */}
          </div>
          <div className='w-full md:w-auto flex flex-col items-start'>
            <p
              className='py-2 font-semibold cursor-pointer'
              onClick={() => {
                if (pathname === '/') {
                  document.getElementById('how-it-works')?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                } else {
                  navigate('/#how-it-works');
                }
              }}
            >
              How it works
            </p>
            <p
              className='py-2 font-semibold cursor-pointer'
              onClick={() => {
                if (pathname === '/') {
                  document.getElementById('benefits')?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                } else {
                  navigate('/#benefits');
                }
              }}
            >
              Benefits
            </p>

            <p
              className='py-2 font-semibold cursor-pointer'
              // to={'/terms-of-us'}
              onClick={() => {
                if (pathname === '/') {
                  document.getElementById('trust-us')?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                } else {
                  navigate('/#trust-us');
                }
              }}
            >
              Trust us
            </p>
          </div>

          <div className='flex flex-col gap-3 items-start mt-7 md:mt-0 md:items-end col-span-2 md:col-span-1 lg:relative'>
            <p className='text-sm font-bold lg:hidden block'>
              Download the app
            </p>
            <div className='flex flex-row md:flex-col gap-3 lg:absolute lg:-top-16'>
              <p className='text-sm font-bold lg:block hidden'>
                Download the app
              </p>
              <Link to={data?.appDeepLink + ''} target='_blank'>
                <img src={images.iosLogo} alt='' />
              </Link>
              <Link to={data?.googleDeepLink + ''} target='_blank'>
                <img src={images.androidLogo} alt='' />
              </Link>
            </div>
          </div>
        </div>
      </footer>
      <div className='flex flex-col-reverse mx-auto md:mx-0 md:flex-row px-4 md:px-12 justify-between font-[400] items-center py-5 border-t'>
        <div>
          <div className='flex flex-col-reverse md:flex-row md:text-[14px] text-center'>
            <p className='font-[400]'>
              © {new Date().getFullYear()} Savers App. All rights reserved.
            </p>
            {/* <div className=' md:mx-0 mb-4 md:mb-0'>
              <Link
                to='/t&c-privacy#termsOfUse'
                className='font-[400] mx-4 underline'
                onClick={() => {
                  if (pathname.includes('t&c-privacy')) {
                    document.getElementById('termsOfUse')?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }
                }}
              >
                Terms of use
              </Link>
              <Link
                to='/t&c-privacy#privacyPolicy'
                className='font-[400] mx-4 underline'
                onClick={() => {
                  if (pathname.includes('t&c-privacy')) {
                    document.getElementById('privacyPolicy')?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }
                }}
              >
                Privacy Policy
              </Link>
            </div> */}
          </div>
        </div>
        <div className='flex flex-row mb-8 md:mb-0' style={{ display: 'none' }}>
          <div className='w-12 h-12 bg-white rounded-full flex items-center justify-center mx-2'>
            <FaFacebookF size={24} className='text-primary' />
          </div>
          <div className='w-12 h-12 bg-white rounded-full flex items-center justify-center mx-2'>
            <FaInstagram size={24} className='text-primary' />
          </div>
          <div className='w-12 h-12 bg-white rounded-full flex items-center justify-center mx-2'>
            <TiSocialLinkedin size={24} className='text-primary' />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
