import { Route, BrowserRouter, Routes } from "react-router-dom";
import B2CLanding from "./pages/b2c/B2CLanding";
import { Suspense, useEffect } from "react";
import Layout from "./layout/Layout";
import Spinner from "./components/spinner/Spinner";
import FaqPage from "./pages/faq/FaqPage";
import AboutUs from "./pages/aboutUs/AboutUs";
import TermsAndConditionsPage from "./pages/termsAndConditions/TermsAndConditions";
import PrivacyPolicyPage from "./pages/privacyPolicy/PrivacyPolicy";
import { useFetchOrganizationDetails } from "./hooks/useFetchOrganizationDetails";
import EmailVerification from "./pages/thankyou/EmailVerification";
const changeFavicon = (faviconUrl: string): void => {
  // Find the existing favicon element
  const existingLink =
    document.querySelector<HTMLLinkElement>("link[rel='icon']");
  // If it exists, remove it
  if (existingLink) {
    existingLink.parentNode?.removeChild(existingLink);
  }
  // Create a new link element
  const newLink = document.createElement("link");
  newLink.rel = "icon";
  newLink.href = faviconUrl;
  // Append the new favicon link to the head
  document.head.appendChild(newLink);
};
export default function App() {
  const { data, loading } = useFetchOrganizationDetails();
  useEffect(() => {
    const defaultFavIcon = "/saversclubfavicon.ico";
    // Set the favicon when the component mounts
    if (data?.icon) {
      changeFavicon(data?.icon);
    } else {
      changeFavicon(defaultFavIcon);
    }
  }, [data, loading]);
  return (
    <BrowserRouter future={{ v7_startTransition: true }}>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route path="/thankyou" element={<EmailVerification />} />
          <Route element={<Layout />}>
            <Route
              path="/"
              element={<B2CLanding data={data} loading={loading} />}
            />
            <Route path="/business-faq" element={<FaqPage />} />
            <Route path="/about-us" element={<AboutUs data={data} />} />
            {/* <Route path='/contact-us' element={<ContactUs />} /> */}

            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditionsPage />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route
              path="*"
              element={<B2CLanding data={data} loading={loading} />}
            />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
