import { images } from '../../constants/images';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import TabbedSections from './TabbedSections';
import QuestionsDropdown from '../../components/questionsDropdown/QuestionsDropdown';
import { OrganizationDetails } from '../../hooks/useFetchOrganizationDetails';
import Spinner from '../../components/spinner/Spinner';

interface Props {
  data: OrganizationDetails | null;
  loading: boolean;
}
const B2CLanding = ({ data, loading }: Props) => {
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(1);
  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.substring(1);
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);

  return (
    <>
      <div className='flex flex-col md:flex-row items-center md:hidden flex-grow-0 bg-[#F8F9FF] align-middle px-[18px] py-8'>
        <div className='flex items-center w-full pr-0'>
          <h2 className='text-[1.75rem] leading-[1.65rem] font-bold mb-4'>
            SPEND. EARN. ENJOY
          </h2>
        </div>
        <div>
          <p className='text-base md:text-[1.25rem] md:leading-[31px] font-[400] text-[#2D2D2D] w-full md:w-[90%]'>
            Earn cashback at thousands of merchants when you shop, dine, or book
            hotels. No sign-up costs, monthly fees, coupons or loyalty cards
            required – simply download our app, search participating merchants
            and pay with your linked cards. It´s uncapped, unlimited real money
            paid onto your chosen card every month!
          </p>
        </div>
        <div className='w-full'>
          <h2 className='font-semibold text-lg my-3'>
            Click to download our free app!
          </h2>
          <div className='flex gap-4 mx-auto mt-2'>
            <Link to={data?.appDeepLink + ''} target='_blank'>
              <img src={images.iosLogo} alt='' />
            </Link>
            <Link to={data?.googleDeepLink + ''} target='_blank'>
              <img src={images.androidLogo} alt='' />
            </Link>
          </div>
        </div>
        <div className='mb-2 md:mb-9 mt-11 w-fit sm:w-[25rem]'>
          <img
            src={images.heroImageB2CSVG}
            alt='Banner 1'
            className='w-full h-auto object-cover'
          />
        </div>
      </div>
      <div className='  hidden md:flex bg-[#F8F9FF] justify-center'>
        {/* Mobile view */}

        <div className='flex flex-col-reverse md:flex-row items-center justify-center mt-[4.6rem] mb-7'>
          {/* Left Section (Text) */}
          <div className='w-full md:w-1/2 max-w-[695px]'>
            <h2 className='hidden md:block text-[2.75rem] md:text-[3rem] leading-[3rem] font-bold mb-4'>
              SPEND. EARN. ENJOY
            </h2>
            <p className='text-base md:text-[1.25rem] md:leading-[31px] font-[400] text-[#2D2D2D] w-full md:w-[90%]'>
              Earn cashback at thousands of merchants when you shop, dine, or
              book hotels. No sign-up costs, monthly fees, coupons or loyalty
              cards required – simply download our app, search participating
              merchants and pay with your linked cards. It´s uncapped, unlimited
              real money paid onto your chosen card every month!
            </p>
            <p className='mt-10'>Scan or click to download our free app!</p>

            <div className='flex gap-8 mt-6'>
              {loading ? (
                <Spinner />
              ) : (
                <div>
                  <img src={data?.qrCode} alt='' className='w-36 h-36' />
                </div>
              )}
              <div className='flex gap-4 self-end'>
                <Link to={data?.appDeepLink + ''} target='_blank'>
                  <img src={images.iosLogo} alt='' />
                </Link>
                <Link to={data?.googleDeepLink + ''} target='_blank'>
                  <img src={images.androidLogo} alt='' />
                </Link>
              </div>
            </div>
          </div>

          {/* Right Section (Image) */}
          <div className='hidden md:block md:max-w-[35.8rem] w-[25rem] lg:w-[35.8rem]'>
            <img
              src={images.heroImageB2CSVG}
              alt='Banner 1'
              className='w-full h-auto object-cover'
            />
          </div>
        </div>
      </div>

      <div id='how-it-works'></div>
      <div className='container-fluid md:container mx-auto mb-2 md:mb-10'>
        <h2 className='text-2xl md:text-[2.375rem] md:leading-3 mt-12 md:mt-20 mb-2.5 md:mb-6 text-center font-bold text-[#1133BB]'>
          How it works.
        </h2>
        <p className='text-center text-lg md:text-3xl leading-5 mb-8 md:mb-12 font-medium'>
          It’s as easy as 1-2-3
        </p>

        <div className='flex justify-center text-center'>
          {/* Image Container */}
          <div className='hidden lg:flex justify-center items-center space-x-4'>
            {/* Step 1 Image */}
            <img
              src={
                activeStep === 1
                  ? images.howItWorksDesk1
                  : images.howItWorksDeskNotext1
              }
              alt='Step 1'
              className='md:cursor-pointer'
              onClick={() => setActiveStep(1)}
            />

            {/* Step 2 Image */}
            <img
              src={
                activeStep === 2
                  ? images.howItWorksDesk2
                  : images.howItWorksDeskNotext2
              }
              alt='Step 2'
              className='md:cursor-pointer'
              onClick={() => setActiveStep(2)}
            />

            {/* Step 3 Image */}
            <img
              src={
                activeStep === 3
                  ? images.step3Expanded
                  : images.howItWorksDeskNotext3
              }
              alt='Step 3'
              className='md:cursor-pointer'
              onClick={() => setActiveStep(3)}
            />
          </div>
          <div className='flex lg:hidden flex-col'>
            <img className='py-2' src={images.howItWorksMobile1} alt='' />
            <img className='py-2' src={images.howItWorksMobile2} alt='' />
            <img className='py-2' src={images.howItWorksMobile3} alt='' />
          </div>

          {/* Content Display */}
          {/* {renderContent()} */}
        </div>
      </div>
      <div className='bg-white'>
        <div className='flex items-center justify-center'>
          {/* Left Section (Text) */}
          <div className='flex flex-col md:flex-row justify-between items-center'>
            <div
              className='w-fit sm:w-[26rem] lg:w-[30rem] py-4 pr-4 pl-4 lg:pl-10 h-full flex flex-col'
              id='partner-with-us'
            >
              <h2 className='text-2xl md:text-[2.375rem] md:leading-10 my-4 md:my-8 font-bold text-[#1133BB]'>
                Find offers near you.
              </h2>

              <p className='text-[1.25rem] leading-[31px] font-[400] text-[#2D2D2D] md:w-[90%] mb-1 md:mb-10'>
                Finding offers near you has never been easier. Use our app to
                quickly locate nearby cashback offers and start saving on your
                everyday purchases. <br />
                <br />
                Enjoy the convenience and maximize your savings today!
              </p>
              <div className='mt-auto mb-4 md:block hidden'>
                <h3 className='font-bold text-xl ml-1'>Download the app</h3>
                {/* <img src={images.IosAndroid} alt='' />{' '} */}
                <div className='flex gap-4 mx-auto mt-3'>
                  <Link to={data?.appDeepLink + ''} target='_blank'>
                    <img src={images.iosLogo} alt='' />
                  </Link>
                  <Link to={data?.googleDeepLink + ''} target='_blank'>
                    <img src={images.androidLogo} alt='' />
                  </Link>
                </div>
              </div>
            </div>

            {/* Right Section (Image) */}
            <div className='w-full md:w-1/2 hidden md:flex'>
              <img
                src={images.findOfferHeroImg}
                alt='Banner 1'
                className='h-auto object-cover'
              />
            </div>
            <div className='w-full md:w-1/2 flex md:hidden'>
              <img
                src={images.findOfferMobile}
                alt='Banner 1'
                className='w-[40rem] h-auto object-cover'
              />
            </div>
            <div className='mt-auto mb-4 md:hidden block w-fit'>
              <h3 className='font-bold text-xl ml-2'>Download the app</h3>
              <div className='flex gap-4 px-2 mx-auto mt-4 mb-10'>
                <Link to={data?.appDeepLink + ''} target='_blank'>
                  <img src={images.iosLogo} alt='' />
                </Link>
                <Link to={data?.googleDeepLink + ''} target='_blank'>
                  <img src={images.androidLogo} alt='' />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='trust-us'></div>
      <div className='bg-[#F8F9FF]'>
        <div className='container-fluid md:container mx-auto md:mb-16 py-8 md:py-8'>
          <h2 className='text-2xl md:text-[2.375rem] md:leading-3 md:mt-24 mb-2 md:mb-6 text-center font-bold text-[#1133BB]'>
            Why it’s safe.
          </h2>
          <p className='hidden md:block text-[1.375rem] leading-7 md:text-[1.75rem] md:leading-3 md:mt-8 md:mb-6 text-center font text-[#1133BB]'>
            Your privacy is important to us.
          </p>
          <p className='md:hidden block text-[1.375rem] leading-7 md:text-[1.75rem] md:leading-3 md:mt-8 md:mb-6 text-center font text-[#1133BB]'>
            Member privacy is important <br /> to us.
          </p>
          <p className='text-[1.375rem] leading-7 md:text-[1.75rem] mt-4 md:mt-16 md:mb-6 text-center font-medium text-[#00C389]'>
            We’re serious about security and protecting you
          </p>
          <div className='my-8 md:my-20'>
            <div className='flex flex-col md:flex-row items-center mx-auto p-4 max-w-5xl'>
              <div className='w-full md:w-1/4 flex justify-center md:justify-center mb-4 md:mb-0'>
                <img
                  src={images.privacyIcon1}
                  alt='Description'
                  className='w-[70px] object-cover'
                />
              </div>

              {/* Text Section (3/4th on Desktop, Full Width on Mobile) */}
              <div className='w-full md:w-3/4 items-center md:items-start md:ml-4 text-center md:text-left'>
                {/* Heading and Description Wrapper */}
                {/* <div className="flex flex-col md:flex-row items-center"> */}
                {/* Heading */}
                <h2 className='text-xl font-bold md:mr-4 leading-8'>
                  We don’t store your card
                </h2>
                <p className='text-xl mt-2 md:mt-0 leading-8'>
                  We don’t store any credit or banking information. All credit
                  or debit card information is stored with Spreedly, a leading
                  platform that supports billions of global transactions via
                  their highly secure and compliant vault systems.
                </p>
                {/* </div> */}
                {/* Description */}
              </div>
            </div>
            <div className='flex flex-col md:flex-row items-center mx-auto p-4 max-w-5xl'>
              <div className='w-full md:w-1/4 flex justify-center md:justify-center mb-4 md:mb-0'>
                <img
                  src={images.privacyIcon2}
                  alt='Description'
                  className='w-[70px] object-cover'
                />
              </div>

              {/* Text Section (3/4th on Desktop, Full Width on Mobile) */}
              <div className='w-full md:w-3/4 items-center md:items-start md:ml-4 text-center md:text-left'>
                {/* Heading and Description Wrapper */}
                {/* <div className="flex flex-col md:flex-row items-center"> */}
                {/* Heading */}
                <h2 className='text-xl font-bold md:mr-4 leading-8'>
                  We don’t sell your data
                </h2>
                <p className='text-xl mt-2 md:mt-0 leading-8'>
                  We don’t share any personal information for marketing purposes
                  – all data is anonymized so that you cannot be identified. If
                  you want to understand more about how we keep your data and
                  information secure, please read our Privacy Policy.
                </p>
                {/* </div> */}
                {/* Description */}
              </div>
            </div>
            <div className='flex flex-col md:flex-row items-center mx-auto p-4 max-w-5xl'>
              <div className='w-full md:w-1/4 flex justify-center md:justify-center mb-4 md:mb-0'>
                <img
                  src={images.privacyIcon3}
                  alt='Description'
                  className=' w-[70px] object-cover'
                />
              </div>

              {/* Text Section (3/4th on Desktop, Full Width on Mobile) */}
              <div className='w-full md:w-3/4 items-center md:items-start md:ml-4 text-center md:text-left'>
                {/* Heading and Description Wrapper */}
                {/* <div className="flex flex-col md:flex-row items-center"> */}
                {/* Heading */}
                <h2 className='text-xl font-bold md:mr-4 leading-8'>
                  We are secure
                </h2>
                <p className='text-xl mt-2 md:mt-0 leading-8'>
                  We use bank-level security to ensure your information is safe
                  and secure. We are compliant with 3-D security to prevent
                  unauthorized access, so your wallet is safe.
                </p>
                {/* </div> */}
                {/* Description */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='benefits'>
        <TabbedSections />
      </div>
      <div className='companies-image-container my-16 block mx-auto'>
        {/* <hr className='text-gray-500 w-full py-2 md:py-4 md:w-[90%] mx-auto' /> */}
        <div className='companies-image-row md:justify-center'>
          <div className='company-images md:gap-4'>
            <img className='w-[6rem] md:w-[7rem]' src={images.ihop} alt='' />
            <img
              className='w-[6rem] md:w-[7rem]'
              src={images.applebees}
              alt=''
            />
            <img
              className='w-[6rem] md:w-[8.68rem]'
              src={images.booking}
              alt=''
            />
            <img className='w-[6rem] md:w-[7rem]' src={images.macys} alt='' />
            <img
              className='w-[6rem] md:w-[7.25rem]'
              src={images.walmart}
              alt=''
            />
            <img
              className='w-[6rem] md:w-[8.12rem]'
              src={images.expedia}
              alt=''
            />
            <img className='w-[6rem] md:w-[7rem]' src={images.riteAid} alt='' />
            <img
              className='w-[6rem] md:w-[7rem]'
              src={images.cvsPharmacy}
              alt=''
            />
            <img className='w-[6rem] md:w-[7rem]' src={images.newegg} alt='' />
          </div>
        </div>
        <div className='companies-image-row md:justify-center'>
          <div className='company-images md:gap-4'>
            <img className='w-[6rem]' src={images.bestBuy} alt='' />
            <img className='w-[6.43rem]' src={images.outback} alt='' />
            <img className='w-[6rem]' src={images.sephora} alt='' />
            <img className='w-[6rem]' src={images.temu} alt='' />
            <img className='w-[9.25rem]' src={images.samsClub} alt='' />
            <img className='w-[6rem]' src={images.target} alt='' />
            <img className='w-[6rem]' src={images.kroger} alt='' />
            <img className='w-[6rem]' src={images.apple} alt='' />
            <img className='w-[11.125rem]' src={images.hotels} alt='' />
            <img className='w-[6rem]' src={images.jd} alt='' />
          </div>
        </div>
        {/* <hr className='text-gray-500 w-full mt-5 md:mt-5 md:w-[90%] mx-auto' /> */}
      </div>

      <QuestionsDropdown />

      {/* <Footer /> */}
    </>
  );
};

export default B2CLanding;
